<template>
  <div class="profile-container">
    <!-- v-if="!$vuetify.breakpoint.smAndDown" -->
    <Breadcrumb :items="breadCrumbs" />
    <v-container class="profile-section">
      <router-view></router-view>
    </v-container>
  </div>
</template>
<style lang="scss">
.profile-container {
  .profile-navigation-menu.v-tabs {
    .v-tabs-slider {
      background-color: orange !important;
    }
    .v-tab {
      max-width: 25%;
      padding: 0px 20px;
      letter-spacing: 0.1px !important;
      font-weight: bold;
      border-bottom: 2px solid var(--v-primary-base);
      @media #{map-get($display-breakpoints, 'xs-only')} {
        padding: 0px 10px;
      }

      span {
        font-size: 12px;
        text-transform: none;
        color: var(--v-primary-base);
        .v-icon {
          color: inherit;
          margin-right: 5px;
        }
      }
      &.v-tab--active {
        span {
          color: orange !important;
        }
      }
    }
  }
}
</style>
<script>
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";

import { mapGetters } from "vuex";

export default {
  name: "Profile",
  components: { Breadcrumb },
  computed: {
    ...mapGetters({
      getLegalById: "cart/getLegalById"
    }),

    breadCrumbs() {
      let pathArray = this.$route.path.split("/");
      pathArray.shift();
      const breadCrumbs = [{ href: "/aliperme", text: "Homepage" }];
      // needed to handle the intermediary entries for nested vue routes
      let breadcrumb = "";
      // let lastIndexFound = 0;

      for (let i = 1; i < pathArray.length; ++i) {
        // from 1 to skip "Area personale"
        breadcrumb = `${breadcrumb}${"/profile/"}${pathArray[i]}`;
        if (
          this.$route.matched[i] &&
          Object.hasOwnProperty.call(this.$route.matched[i], "meta") &&
          Object.hasOwnProperty.call(this.$route.matched[i].meta, "breadCrumb")
        ) {
          breadCrumbs.push({
            href: breadcrumb,
            // i !== 0 && pathArray[i - (i - lastIndexFound)]
            //   ? "/" + pathArray[i - (i - lastIndexFound)] + breadcrumb
            //   : breadcrumb,
            exact: false,
            disabled: i + 1 === pathArray.length,
            text: this.$route.matched[i].meta.breadCrumb || pathArray[i]
          });
          // lastIndexFound = i;
          // breadcrumb = "";
        }
      }
      return breadCrumbs;
    }
  }
};
</script>
